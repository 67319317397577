import React, { useEffect } from 'react'
import MyBtn from '../../utils/MyBtn'
import { initFlowbite } from 'flowbite';


const topics = [
{id: 1, name : "SOUND ISLAMIC CREED", desc : "Here we teach the knowledge of the Aqeedah Ahlus-Sunnah wal-Jama'ah. This is the foundation and the bedrock of our knowledge. Aqeedah linguistically, means to bind firmly and tightly. In Shariah, it is a belief held strongly in the heart and utter it in the mouth that Allah is one in His essence, His creations, His worship and His Names and attributes.", imgURL: "../images/knowledge.jpg"},
{id: 2, name : "ARABIC LANGUAGE", desc : "Here we teach the language for which the Holy Quran was revealed to the prophet Muhammad. In this course, we cover most Arabic grammar, Arabic morphology topics needed to help you learn Arabic quickly.", imgURL: "../images/arabic.jpg"},
{id: 3, name : "GOOD ETIQUTES", desc : "Here we teach students Islamic good morals and manners. Among the Etiquettes are obedience to Allah, to the Parents, the teachers, those in position of Authority, visiting the sick, praying five times daily and fixing broken family ties etc.", imgURL: "../images/book.jpg"},
{id: 4, name : "QURAN", desc : "Here we teach students how to read  Quran fluently and correctly with the application of the rules of articulation of Qur'anic letters. We also teach how to memorize Quran in its entirety.", imgURL: "../images/quran.jpg"},
];

function MyCard({topic}) {
    return (
    <>
    {/*  */}
    
    <div className="md:w-72 m-2  bg-white border border-gray-200 rounded-lg shadow">
        <a href="#">
            <img className="rounded-t-lg" src={topic.imgURL} alt="" />
        </a> 
        <div className="p-5">
            <a href="#">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{topic.name}</h5>
            </a>
            <div id={`default-modal-${topic.id}`} tabIndex={-1} aria-hidden="true" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    { /* Modal content */ }
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        { /* Modal header */ }
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl text-center font-semibold text-gray-900 dark:text-white">
                                {topic.name}
                            </h3>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide={`default-modal-${topic.id}`}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        { /* Modal body */ }
                        <div className="p-4 md:p-5 space-y-4">
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                {topic.desc}
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
            <button data-modal-target={`default-modal-${topic.id}`} data-modal-toggle={`default-modal-${topic.id}`} className="inline-flex items-center px-3 py-2 my-4 text-sm font-medium text-center text-white bg-yellow-600 rounded-lg hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">READ MORE</button>
            
        </div>
    </div>
    </>
    )
}




function TeachSec() {
    useEffect(() => {
        initFlowbite();
    }, []);
    return (
    <>
    <div className="teach mx-9 md:mx-6">
        <h1 className="text-center uppercase font-bold text-2xl">WHAT WE TEACH</h1>
        <hr className="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700" />
        <div className="flex flex-wrap cards justify-center mt-10">
            
            {topics.map((topic) => (
                <MyCard topic={topic}  />
                ) )}
                
                {/* Repeat the above card for each item */}
            </div>
            {/* <MyBtn to="/what-we-teach">VIEW ALL</MyBtn> */}
        </div>
        </>
        )
    }
    
    export default TeachSec
    