
import React, { useEffect, useState } from 'react';

// const languages = [
//   { code: 'en', name: 'English' },
//   { code: 'ar', name: 'Arabic' },
//   { code: 'yo', name: 'Yoruba' },
// ];

function LanguageSelector() {
  // const [selectedLanguage, setSelectedLanguage] = useState('ar'); // Set Arabic as the default language

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
  //   script.async = true;
  //   document.body.appendChild(script);

  //   window.googleTranslateElementInit = () => {
  //     new window.google.translate.TranslateElement({
  //       pageLanguage: 'ar', // Default page language is Arabic
  //       includedLanguages: 'en,ar,yo',
  //       layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
  //       autoDisplay: true,
  //     }, 'google_translate_element');
    
      
  //     // Automatically change the page to Arabic after initializing the Google Translate element
  //     const intervalId = setInterval(() => {
  //       const googleTranslateElement = document.querySelector('.goog-te-combo');
  //       if (googleTranslateElement) {
  //         googleTranslateElement.value = 'ar';
  //         googleTranslateElement.dispatchEvent(new Event('change'));
  //         clearInterval(intervalId); // Clear the interval once the language is changed
  //       }
  //     }, 100);
  //   };

  //   return () => {
  //     document.body.removeChild(script);
  //     delete window.googleTranslateElementInit;
  //   };
  // }, []);

  // const handleLanguageChange = (languageCode) => {
  //   setSelectedLanguage(languageCode);
  //   const googleTranslateElement = document.querySelector('.goog-te-combo');
  //   if (googleTranslateElement) {
  //     googleTranslateElement.value = languageCode;
  //     googleTranslateElement.dispatchEvent(new Event('change'));
  //   }
  // };

  return (
    <div>
      {/* <div className="relative inline-block text-left">
        <div>
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
          >
            <i className="fi fi-rr-earth-americas" />
            {languages.find((lang) => lang.code === selectedLanguage)?.name}
          </button>
        </div>

        <div
          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="py-1" role="none">
            {languages.map((language) => (
              <button
                key={language.code}
                className={`block px-4 py-2 text-sm w-full text-left ${
                  language.code === selectedLanguage ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                }`}
                onClick={() => handleLanguageChange(language.code)}
              >
                {language.name}
              </button>
            ))}
          </div>
        </div>
      </div> */}
      <div id="google_translate_element"></div>
    </div>
  );
};

export default LanguageSelector;
