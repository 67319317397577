import React, { useState } from "react";
import { useEffect } from 'react'
import { initFlowbite } from 'flowbite'
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Base from "./pages/Base";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import _404 from "./pages/404";
import RegisterForm from "./pages/RegisterForm";
import Classes from "./pages/Classes";
import TheClass from "./components/Class/TheClass";
import { classesData } from "./Data/Data";
import Charity from "./pages/Charity";
import SchLectures from "./pages/SchLectures";
import SchEvents from "./pages/SchEvents";
import SchLecturesDetails from "./pages/SchLecturesDetails";
import SchCalender from "./pages/SchCalender";
import BlogPage from "./pages/BlogPage";

function App() {

    useEffect(() => { 
        initFlowbite();
    },[]);

    const [ lecture, setLecture ] = useState({});
    const classes = classesData;

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={ <Base/> }>
                        <Route index element={ <Home setLecture={setLecture} /> }  />
                        <Route path="about" element={ <About/> } />
                        <Route path="school">
                            <Route path="events" element={ <SchEvents/> } />
                            <Route path="lectures" element={ <SchLectures setLecture={setLecture}/> } />
                            <Route path="calender" element={ <SchCalender/> } />
                        </Route>
                        <Route path="/lecture" element={ <SchLecturesDetails lecture={lecture}/> } />
                        <Route path="classes" element={<Classes/>}/>
                        <Route path="classes">
                            {classes.map((myclass) => (
                                <Route key={myclass.id} path={myclass.url} element={<TheClass classname={myclass.classname} color={myclass.color} subjects={myclass.subjects} aboutClass={myclass.aboutClass} />} />
                            ))}
                        </Route>
                        <Route path="blog" element={ <BlogPage/> } />
                        <Route path="contact" element={ <Contact/> } />
                        <Route path="*" element= { <_404/> } />
                    </Route>
                    <Route path="/form" element= { <RegisterForm/> } />
                    <Route path="/charity" element={ <Charity/> }/>

                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App;