import React from 'react'

function CharCard({charityIn}) {
    return (
    <>
    <div>
        <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row mb-10">
            <img className="object-cover w-full md:w-1/2 rounded-t-lg h-96 md:rounded-none md:rounded-s-lg" src={charityIn.imgUrl} alt="" />
            <div className="flex flex-col justify-between p-4 leading-normal">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{charityIn.name}</h5>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{charityIn.desc}</p>
                <div className="btn">
                <button data-modal-target="popup-modal" data-modal-toggle="popup-modal"  className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2" type="button">DONATE</button>
                </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default CharCard
