import React from 'react'

function MyBtn({to, children}) {
  return (
    <>
        <div className="text-center mt-6 md:mt-10">
        <a className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 " href={to}>{children}</a>
        </div>
    </>
  )
}

export default MyBtn
