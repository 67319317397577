import React from 'react';
import { useState } from 'react';
import { FaCopy } from 'react-icons/fa';

const CopyButton = ({ textToCopy }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      // Reset copied state after a certain duration
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <button
      data-modal-hide="popup-modal"
      type="button"
      onClick={copyToClipboard}
      className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
    >
      <FaCopy className="me-2" />
      {copied ? 'Copied!' : 'COPY'}
    </button>
  );
};

export default CopyButton;
