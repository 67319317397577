import React from 'react'


function BlogPostCard({ title, content, date , img , showImage = true }) {
  return (
    <article className="p-4 md:p-6 flex flex-col md:flex-row items-center lg:p-8 border-b border-gray-200">
     {
      showImage 
      ?
      <div className="img w-20 md:w-40 me-5 ">
      <img src={img} alt={`${title} image`} />
    </div> 
      :

    <></>
    
     }
      <div className="">
      <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-yellow-900">{title}</h2>
      <p className="mt-2 text-sm md:text-base lg:text-lg">{content}</p>
      <p className="text-xs md:text-sm lg:text-base text-gray-600">Admin - {date}</p>
      </div>
    </article>
  )
}

export default BlogPostCard
