import React from 'react'

function GallerySec() {
  const images = [
    {
      id: 0,
      imgUrl: "../images/student-1.jpg"
    },
    {
      id: 1,
      imgUrl: "../images/student-2.jpg"
    },
    {
      id: 2,
      imgUrl: "../images/student-3.jpg"
    },
    {
      id: 3,
      imgUrl: "../images/student-4.jpg"
    },
    {
      id: 4,
      imgUrl: "../images/student-5.jpg"
    },
    {
      id: 5,
      imgUrl: "../images/student-13.jpg"
    },
    {
      id: 6,
      imgUrl: "../images/student-7.jpg"
    },
    {
      id: 7,
      imgUrl: "../images/student-14.jpg"
    },
    {
      id: 8,
      imgUrl: "../images/student-9.jpg"
    },
    {
      id: 9,
      imgUrl: "../images/student-10.jpg"
    },
    {
      id: 10,
      imgUrl: "../images/student-17.jpg"
    },
    {
      id: 11,
      imgUrl: "../images/student-20.jpg"
    },
  ];
  return (
    <>
        <section className="gallery mx-9 md:mx-6">
          <h1 className="text-center uppercase font-bold text-2xl">OUR GALLERY</h1>
          <hr className="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700" />
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {images.map((image) => (
              <div className="relative" key={image.id}>
              <div className="w-full h-full absolute rounded-lg bg-black opacity-50 hover:opacity-0 cursor-pointer"></div>
              <img
                className="h-auto max-w-full rounded-lg"
                // src="../images/student-15.jpg"
                src={image.imgUrl}
                alt=""
              />
            </div>
            ))}
          </div>

        </section>        
    </>
  )
}

export default GallerySec

