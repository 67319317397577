import React from 'react'

function SchEvents() {
  return (
    <>
        <h1>Events</h1>
    </>
  )
}

export default SchEvents
