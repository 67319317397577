import React from "react";
import { FaPhone, FaEnvelope } from "react-icons/fa";

function Contact() {
    return (
        <div className="container mx-auto py-12">
        <h1 className="text-3xl font-semibold mb-8 text-center">Contact Us</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="p-6 bg-white shadow-md rounded-lg">
            <h2 className="text-xl font-semibold mb-4">Contact Details</h2>
            <div className="flex items-center mb-4">
              <FaPhone className="text-gray-600 mr-3" />
            <div>
              <p className="font-semibold">Phone Numbers:</p>
              <ul className="list-disc ml-6">
                <li className="hover:underline">
                  <a className="" href="tel:08081298720">
                    <span >+234 (808) 129 8720</span><br />
                    </a>
                </li>
                <li className="hover:underline">
                  <a className="" href="tel:08184359191">
                        <span >+234 (818) 435 9191</span><br />
                    </a>
                </li>
                <li className="hover:underline">
                  <a className="" href="tel:08107598252">
                        <span >+234 (810) 759 8252</span><br />
                    </a>
                </li>
                <li className="hover:underline">
                  <a className="" href="tel:07019171745">
                        <span >+234 (701) 917 1745</span><br />
                    </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex items-center mb-4">
            <FaEnvelope className="text-gray-600 mr-3" />
            <div>
              <p className="font-semibold">Email:</p>
              <p>
                <a href="mailto:daarurahmah18@gmail.com" className="underline">daarurahmah18@gmail.com</a>
              </p>
            </div>
          </div>
          </div>
            <div className="bg-gray-100 p-6 rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Our Location</h2>
          {/* <iframe
            title="Google Map"
            width="100%"
            height="300"
            frameborder="0"
            style={{ border: 0 }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d194595.9582646537!2dLONGITUDE!3dLATITUDE!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjPCsDE0JzUwLjUiTiAxMDLCsDUyJzA5LjkiRQ!5e0!3m2!1sen!2sus!4v1618921453781!5m2!1sen!2sus"
            allowfullscreen
          ></iframe> */}

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.534448308773!2d3.3948754097980913!3d6.453755023944532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b165b47e85d%3A0xe053ac3dbf08e075!2s19%2C%2032%20Glover%20St%2C%20Lagos%20Island%2C%20Lagos%20102273%2C%20Lagos!5e0!3m2!1sen!2sng!4v1713340169758!5m2!1sen!2sng"  title="Google Map" width="100%" height="300" frameborder="0" style={{ border: 0 }} allowFullScreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    )
}

export default Contact;