'use client';
import React from "react";


import MyCarousel from "../components/Home/MyCarousel";
import CharitySec from "../components/Home/CharitySec";
import TeachSec from "../components/Home/TeachSec";
import GallerySec from "../components/Home/GallerySec";
import RecentLecturesSec from "../components/Home/RecentLecturesSec";
import BriefAbout from "../components/Home/BriefAbout";
import BlogSec from "../components/Home/BlogSec";
// import Image from 'next/image';

function Home({setLecture}) {
    return (
        <>
        <MyCarousel/>
        <hr className="h-px my-20 mx-8  bg-gray-200 border-0 dark:bg-gray-700">
        </hr>
        <BriefAbout/>
        <hr className="h-px my-20 mx-8  bg-gray-200 border-0 dark:bg-gray-700">
        </hr>
        {/* what we teach section */}
        <TeachSec/>
        {/* what we teach section end */}
        <hr className="h-px my-20 mx-8  bg-gray-200 border-0 dark:bg-gray-700"></hr>
        {/* charity sec */}
            <CharitySec />
        {/* charity sec end*/}
        <hr className="h-px my-20 mx-8  bg-gray-200 border-0 dark:bg-gray-700"></hr>
        {/* brief blog section */}
            <BlogSec/>
        {/* brief blog section end */}
        <hr className="h-px my-20 mx-8  bg-gray-200 border-0 dark:bg-gray-700"></hr>
        {/* gallery section */}
            <GallerySec/>
        {/* gallery section end */}
        <hr className="h-px my-20 mx-8  bg-gray-200 border-0 dark:bg-gray-700"></hr>
        {/* recent events */}
            <RecentLecturesSec setLecture={setLecture}/>
        {/* recent events end */}


        </>
        )
    }
    
    export default Home;