import React, { useEffect, useState } from 'react'
import BlogPostCard from '../components/Blog/BlogPostCard'
import { db } from '../firebase';
import { newsData } from '../Data/Data';

function BlogPage() {
   
    const posts = newsData;
    
    
  return (
    <section className="aboutus px-10 md:px-20">
      <h1 className="text-start font-black text-base mb-8">WHAT'S HAPPENING AT THE SCHOOL</h1>
      
        <div className="p-4 md:p-6 lg:p-8">
        {posts.map((post, index) => 
        {
          const date = new Date(post.datePosted.seconds * 1000);
          const formattedDate = date.toUTCString(); 
          return (
          
            <BlogPostCard
              key={index}
              title={post.headline}
              content={post.description}
              date={formattedDate}
              img={post.imageUrl}
            />
          )
        }
        )}
      </div>
    </section>
  )
}

export default BlogPage
