import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/General/Navbar";
import Footer from "../components/General/Footer";


function Base() {
    return (
        <>
        
            <Navbar/>
            <hr className="h-px my-10 mx-8  bg-gray-200 border-0 dark:bg-gray-700"/>
            <Outlet/>
            <hr className="h-px my-20 mx-8  bg-gray-200 border-0 dark:bg-gray-700"/>
            <Footer/>

            
        </>

    )
}
export default Base;