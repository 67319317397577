import React from 'react'
import MyBtn from '../../utils/MyBtn'




function BriefAbout() {
  return (
    <>
        {/* brief about us */}
<div className="about-us">
            <h1 className="text-center uppercase font-bold text-2xl mt-8 md:mt-12">About Us</h1>
            <hr className="w-16 md:w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-6 dark:bg-gray-700" />
            <div className="cont mx-4 md:mx-12 lg:mx-32 mt-6 text-sm text-gray-700">
                <p className="mb-4 md:mb-6">
                DAARU RAHMAH was established on the 18 Dec, 2017 as a center for learning and practicing pristine Islam through the knowledge of Arabic language for all ages. We started our mission at No. 19 Glover Street, Lagos Island With a weekend classes in an apartment donated for fisabilillah with an initial enrollment of 7 students. The aim is to nurture the minds of Muslims children. Now the school is  a registered institution with a certificate under CAC in 2019. Presently, the total number of enrollment is up to 200± with qualified teachers and well packaged curriculum suitable for the 21st century learners.
                  
                </p>
                <p className="mb-4 md:mb-6">
                    Step into our halls, and you'll find an inspiring learning environment where tradition meets innovation. From our state-of-the-art facilities to our passionate educators, every aspect of Daaru Rahmah is designed to ignite curiosity and ignite a passion for knowledge. We believe in nurturing not only academic prowess but also character development, instilling values of integrity, compassion, and resilience in our students as they prepare to navigate an ever-changing world.
                </p>
                <p className="mb-4 md:mb-6">
                    Daaru Rahmah isn't just a school; it's a close-knit community where students, parents, and educators collaborate to shape futures and forge lifelong bonds. Located conveniently in East Lagos, our school serves as a hub of cultural diversity and academic excellence. Whether it's through engaging extracurricular activities, community outreach programs, or interactive classroom experiences, we empower our students to become global citizens who embrace diversity, champion inclusivity, and strive for excellence in all endeavors. Join us at Daaru Rahmah, where every child's potential is nurtured, celebrated, and transformed into boundless opportunities for success.
                </p>
            </div>
                <MyBtn to="/about">Read More</MyBtn>
                
</div>
{/* brief about us end */}
    </>
  )
}

export default BriefAbout
