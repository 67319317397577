import React from 'react'
// import { FaFacebookF, FaInstagram } from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";


function Footer() {
    return (
    <>           
    
    <footer className="bg-black">
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
            <div className="md:flex md:justify-between">
                <div className="mb-6 md:mb-0">
                    <a href="" className="flex items-center">
                        <img src="../logo1.png" className="h-10 me-3" alt="daarurahmah logo" />
                        <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
                            DAARURAHMAH
                        </span>
                    </a>
                    <br />
                    <a className="text-gray-300 ps-3 hover:underline" href="tel:08081298720">
                        <span >+234 (808) 129 8720</span><br />
                    </a>
                    <a className="text-gray-300 ps-3 hover:underline" href="tel:08184359191">
                        <span >+234 (818) 435 9191</span><br />
                    </a>
                    <a className="text-gray-300 ps-3 hover:underline" href="tel:08107598252">
                        <span >+234 (810) 759 8252</span><br />
                    </a>
                    <a className="text-gray-300 ps-3 hover:underline" href="tel:07019171745">
                        <span >+234 (701) 917 1745</span><br />
                    </a>
                    <a className="text-gray-300 ps-3 hover:underline" href="tel:08062063385">
                        <span >+234 (806) 206 3385</span><br />
                    </a>
                </div>
                <div className="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-3 text-center">
                    <div>
                        <h2 className="mb-6 text-sm font-semibold  uppercase text-white">
                            Quick Links
                        </h2>
                        <ul className=" text-gray-200 font-medium">
                            <li className="mb-4">
                                <a href="/" className="hover:underline">
                                    Home
                                </a>
                            </li>
                            <li className="mb-4">
                                <a href="/about" className="hover:underline">
                                    About
                                </a>
                            </li>
                            <li>
                                <a href="/contact" className="hover:underline">
                                    Contact    
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* <br /> */}
                    <div>
                        <h2 className="mb-6 text-sm font-semibold  uppercase text-white">
                            Programs
                        </h2>
                        <ul className=" text-gray-200 font-medium">
                            <li className="mb-4">
                                <a
                                href="/school/events"
                                className="hover:underline "
                                >
                                School Events
                            </a>
                        </li>
                        <li>
                            <a
                            href="/school/lectures"
                            className="hover:underline"
                            >
                            School Lectures
                        </a>
                    </li>
                </ul>
            </div>
            {/* <br /> */}
            <div>
                <h2 className="mb-6 text-sm font-semibold  uppercase text-white">
                    School
                </h2>
                <ul className=" text-gray-200 font-medium">
                    <li className="mb-4">
                        <a href="/classes" className="hover:underline">
                            Our Classes
                        </a>
                    </li>
                    <li>
                        <a href="#" className="hover:underline">
                            Our Gallery
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <br /><br />
        <div className="text-white md:text-start text-center">
            <ul>
                <li>
                    <span className="font-semibold">EMAIL</span> <br />
                    <a href="#" className="hover:underline text-sm">
                        daarurahmah18@gmail.com
                    </a>
                </li>
                <li>
                    <span className="font-semibold">ADDRESS</span> <br />
                    <a href="#" className="hover:underline text-sm">
                        19/32 GLOVER STREET, LAGOS ISLAND
                    </a>
                </li>
                <li>
                    <span className="pt-5">OPENS</span>
                    <div className="">
                        MON - THUR and SAT - SUN
                    </div>
                </li>
            </ul>
        </div>
        
    </div>
    <hr className="my-6  sm:mx-auto border-gray-100 lg:my-8" />
    <div className="sm:flex sm:items-center sm:justify-between">
        <span className="text-sm  sm:text-center text-white">
            © 2024{" "}
            <a href="https://flowbite.com/" className="hover:underline">
                Daarurahmah
            </a>
            . All Rights Reserved.
        </span>
        <div className="flex mt-4 sm:justify-center sm:mt-0">
            <a href="https://www.facebook.com/profile.php?id=100083295641377" target="_blank"  className="me-5 text-green-300 text-xl hover:text-white">
                {/* <FaFacebookF /> */}
            </a>
            <a href="https://www.instagram.com/daaru_rahmah_islamic_school?igsh=MXY3eGw2cWZudzZxag==" target="_blank" className="me-5 text-green-300 text-xl hover:text-white">
                {/* <FaInstagram /> */}
            </a>
            <a href="https://x.com/DaaruAnd/status/1201083172982861824?t=T2cDso2E_uFozTR7_s993Q&s=08" target="_blank" className="me-5 text-green-300 text-xl hover:text-white" >
                {/* <FaXTwitter /> */}
                <i className="fi-brands-twitter-alt"/>
            </a>
            <a href="tel:" target="_blank" className="me-5 text-green-300 text-xl hover:text-white" >
                <i className="fi fi-brands-whatsapp" />
                <img className="text-white" src="../src/assets/uicons-brands/svg/fi-brands-whatsapp.svg" alt="" />
            </a>
        </div>
    </div>
</div>
</footer>



</>
)
}

export default Footer
