import React from "react";

function _404() {
    return (
        <>
            <h1>Page Not Found!</h1>
        </>
    )
}

export default _404;