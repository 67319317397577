import React from 'react'
import { lecturesData } from '../Data/Data';
import { useNavigate } from 'react-router-dom';

function SchLectures({setLecture}) {

    const lectures = lecturesData.reverse();
    const navigate = useNavigate();
 
    const setLec = (lec) => {
     setLecture(lec);
     navigate("/lecture");
    }

  return (
    <>
        <section className="schlecture px-6 md:px-9">
            <h1 className="text-xl md:text-3xl font-bold mb-5 md:mb-10 text-blue-700">OUR LECTURES</h1>
             <div className="grid grid-cols-1 md:grid-cols-3 gap-4 ">
                {lectures.map((lecture) => (
                    <div className="w-full event pb-8 md:pb-5">
                    <div className="max-w-sm bg-white border flex flex-col justify-center items-center border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <div className="w-full flex justify-center items-center h-64 b0">
                            <img className="max-w-[250px] w-full h-auto rounded-t-lg" src={lecture.imgUrl} alt="" />
                        </div>
                        <div className="w-full p-5">
                            
                            <h5 className="mb-2 text-xl font-black tracking-tight text-gray-900 dark:text-white">
                              TOPIC :  <span className="text-yellow-600">{lecture.topic}</span> 
                            </h5>
                           
                            <p className="mb-3 font-normal text-base text-gray-700 dark:text-gray-400"> {lecture.briefExpla} </p>
                            <div className="datetime flex justify-between pb-2">
                            <p className="text-sm font-bold text-gray-600">{lecture.time}</p>
                            <p className="text-sm font-bold text-gray-600">{lecture.date}</p>
                           </div>
                           <button onClick={() => setLec(lecture)} className="inline-flex w-3/4 items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                Read more
                            <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M1 5h12m0 0L9 1m4 4L9 9"
                                />
                            </svg>
                            </button>
                        </div>
                    </div>
                </div>
                ))}
            </div>
        </section>
    </>
  )
}

export default SchLectures
