import React from 'react'
import { lecturesData } from '../../Data/Data';
import { Link, useNavigate } from 'react-router-dom';

function RecentLecturesSec({setLecture}) {

    const navigate = useNavigate();
   const lectures = lecturesData.slice(-3);

   const setLec = (lec) => {
    setLecture(lec);
    navigate("/lecture");
   }

  return (
    <>
        <div className="">
            <h1 className="text-center uppercase font-bold text-2xl">RECENT LECCTURES</h1>
                <hr className="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700" />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mx-9 md:mx-6">
                {lectures.map((lecture) => (
                    <div key={lecture.id} className="event">
                    <div className="max-w-sm bg-white border flex flex-col justify-center items-center border-gray-200 rounded-lg shadow ">
                        <div className=" w-full h-auto">
                            <img className="rounded-t-lg" src={lecture.imgUrl} alt="" />
                        </div>
                        <div className="w-full p-5">
                            
                            <h5 className="mb-2 text-xl font-black tracking-tight text-gray-900 dark:text-white">
                              TOPIC :  <span className="text-yellow-600">{lecture.topic}</span> 
                            </h5>
                           
                            <p className="mb-3 font-normal text-base text-gray-700 dark:text-gray-400"> {lecture.briefExpla} </p>
                            <div className="datetime flex justify-between pb-2">
                            <p className="text-sm font-bold text-gray-600">{lecture.time}</p>
                            <p className="text-sm font-bold text-gray-600">{lecture.date}</p>
                           </div>
                         
                            <button onClick={() => setLec(lecture)} className="inline-flex w-3/4 items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                Read more
                            <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M1 5h12m0 0L9 1m4 4L9 9"
                                />
                            </svg>
                            </button>
                        </div>
                    </div>
                </div>
                ))}
            </div>
        </div>
    </>
  )
}

export default RecentLecturesSec
