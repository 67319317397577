import React from "react";

function About() {
    return (
    <>
    <section className="aboutus px-10 md:px-20">
        <h1 className="text-start font-black text-base mb-8">ABOUT DAARURAHMAH</h1>
        <div className="history mb-10 md:mb-20">
            <h1 className="font-bold text-center text-2xl text-blue-700">OUR HISTORY</h1><br />
            <p className="text-green-900">  At Daaru Rahmah, nestled in the heart of Lagos Island, we take immense pride in our rich legacy of nurturing young minds. With a history steeped in academic excellence and a dedication to fostering a vibrant learning community, we stand as a beacon of educational enlightenment. Our commitment to providing quality Arabic education is unwavering, ensuring that every student who walks through our doors embarks on a journey of intellectual growth and cultural enrichment.</p>
            
        </div>
        <div className="mission mb-10 md:mb-20">
            <h1 className="font-bold text-center text-2xl text-blue-700">OUR MISSION</h1><br />
            <ul className="space-y-1 text-gray-500 list-inside ">
                <li className="flex items-center">
                    <svg className="w-3.5 h-3.5 me-2 text-green-500  flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    To produce students who can recite the whole or part of the Quran and other books of Sunnah by heart.
                </li>
                <li className="flex items-center">
                    <svg className="w-3.5 h-3.5 me-2 text-green-500  flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    To produce students who are fluent in speaking and comprehending Arabic language vis a vis reading and writing.
                </li>
                <li className="flex items-center">
                    <svg className="w-3.5 h-3.5 me-2 text-gray-500  flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    To impact Islamic characters, morals and ethics into our students through teachings of Quran and Sunnah.
                </li>
                <li className="flex items-center">
                    <svg className="w-3.5 h-3.5 me-2 text-gray-500  flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    To raise future leaders who are not only knowledgeable but also understand the true meaning of piety.
                </li>
            </ul>
            
        </div>
        <div className="vision text-center mb-10 md:mb-20">
            <h1 className="font-bold text-2xl text-blue-700">OUR VISION</h1><br />
            <p className="text-green-900">Our aim is to train the next generation of pious Islamic scholars who would have the knowledge of pristine Islam.
            </p>
            <p className="text-green-900"></p>We wish to have our own Modrosah (school) that will include a Mosque, library, staff offices, quarters for the teachers, lecture hall, etc.
            
        </div>
    </section>
    </>
    )
}

export default About;