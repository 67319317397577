import React from 'react'
import { Link } from 'react-router-dom'

function CharitySec() {
  return (
    <>
        {/* <section className="charity-sec w-full h-52 bg-gray-300"> */}
            

<section class="charity-sec w-full h-auto bg-center bg-no-repeat bg-gray-600 bg-blend-multiply ">
    <div class="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-20">
        <h1 class="mb-4 text-2xl font-extrabold tracking-tight leading-none text-white md:text-3xl lg:text-4xl">READY TO MAKE A DIFFERENCE? </h1>
        <p class="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">Join us in our charity initiatives and be a catalyst for positive change in our community and beyond!</p>
        <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
            
            <Link to="/charity" class="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400">
                Learn more
            </Link>
        </div>
    </div>
</section>

        {/* </section> */}
    </>
  )
}

export default CharitySec
