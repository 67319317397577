import React, { useEffect, useState } from 'react';
import BlogPostCard from '../Blog/BlogPostCard';
import MyBtn from '../../utils/MyBtn';
import { db } from '../../firebase';
import { newsData } from '../../Data/Data';

function BlogSec() {


const posts = newsData.slice(-3);


  return (
    <section className="blog mx-6 md:mx-9">
      <h1 className="text-center uppercase font-bold text-2xl">WHAT'S HAPPENING?</h1>
      <hr className="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 md:p-6 lg:p-8">
      {posts.map((post, index) => {
          // Assuming post.datePosted is an object with seconds and nanoseconds
          const date = new Date(post.datePosted.seconds * 1000);
          const formattedDate = date.toUTCString(); // Customize the date format if needed

          return (
            <BlogPostCard
              key={index}
              title={post.headline}
              content={post.description}
              date={formattedDate}// Use the formatted date
              showImage = {false}
            />
          );
        })}
      </div>
      <MyBtn to="/blog">Read More</MyBtn>
    </section>
  );
}

export default BlogSec;
