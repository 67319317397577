import React, { useEffect } from 'react'
import { FaClock, FaMicrophone } from "react-icons/fa";
import { FaCalendarDays, FaLocationDot, FaPeopleGroup   } from "react-icons/fa6";
import { useNavigate, Link } from 'react-router-dom';

function SchLecturesDetails({lecture}) {

  const navigate = useNavigate();

  // const lecture = props.location.state;
  // console.log(lecture.id)
  useEffect(() => {
     if (lecture.id == null) {
    // alert("empty");
    navigate("/");
  }
  }, []);

  return (
   <>
  
    <section className="lecturedetails px-10 md:px-28 ">
        <h2 className="text-2xl md:text-3xl lg:text-4xl text-center text-yellow-600 font-medium">Assalamu Alaikum wa Rahmatullahi wa Barakatuh</h2>
        <div className="lecturedesc bg-white shadow-2xl shadow-blue-200 mt-5 px-5 py-10 rounded-md">
          <nav>
              <Link to="/" className="logo pt-3">
                  <img src="/images/logo1.png" className="w-[90px] md:w-[150px]" alt="" srcset=""/>
              </Link>
          </nav>
          <h1 className="text-3xl md:text-4xl lg:text-5xl text-center font-medium">{lecture.topic}</h1>
          <div className="briefex">
            <p className="text-center">{lecture.briefExpla}</p>
          </div>
          <div className="date-time flex flex-col justify-between mt-10 md:flex-row">
            <span className="time flex items-center font-bold text-blue-700"><FaCalendarDays className="text-yellow-600 " />{lecture.date}</span>
            <br />
            <span className="time flex items-center font-bold text-blue-700"><FaLocationDot className="text-yellow-600 " />{lecture.venue}</span>
            <br />
            <span className="date flex items-center font-bold text-blue-700"><FaClock className="text-yellow-600" />{lecture.time}</span>
          </div>
          <div className="host mt-10  mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center text-xl sm:text-1xl md:text-2xl lg:text-3xl xl:text-4xl font-bold text-center text-yellow-600">
            <FaPeopleGroup/>
            <h1 className="uppercase">
              {lecture.host}
            </h1>
          </div>
          <div className="speaker text-2xl md:text-3xl lg:text-4xl flex justify-end items-center mt-14">
          <FaMicrophone /> : 
          <h1 className="text-3xl md:text-4xl lg:text-5xl text-center text-gray-600 font-semibold">{lecture.speaker}</h1>
          </div>
          <br />
          <br />

        <div className="">
        <video className="w-full h-auto max-w-full" controls>
          <source src={lecture.videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </div>
          
        </div>
    </section>
   </>
  )
}

export default SchLecturesDetails
