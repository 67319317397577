import React from "react";
import { db2 } from './Firebase';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';




const colorBlue = "text-blue-700";
const colorYellow = "text-yellow-300";
const colorRed = "text-red-700";

// n1 p1 j2

const classesData = [

  {id : 1, url : 'n-1', classname : 'Nursery 1', color: colorBlue, aboutClass: "", subjects: ["Adhkaar", "Reading (Nuurul-bayyan )", "Writing (Tasheelul khattu )", "Arabic vocabulary", "Qur'an Memorization", "Hadith", "Tawheed", "Fiqh", "Nasheed (poem)"]},
  {id : 2, url : 'p-1', classname : 'Primary 1', color: colorYellow, aboutClass: "", subjects: ["Fiqh", "Taoheed", "Arabic language", "Writing skills", "Hadith", "Dictation", "Reading Skills", "Qur'an Memorization", "Adhkaar"]},
  {id : 3, url : 'p-2', classname : 'Primary 2', color: colorYellow, aboutClass: "", subjects: ["Fiqh","Taoheed","Arabiyat","Handwriting","Hadith","Dictation","Reading Skills","Qur'an Memorization","Adhkaar"]},
  {id : 4, url : 'p-3', classname : 'Primary 3', color: colorYellow, aboutClass: "", subjects: [" Qur'an Reading" ,"Qur'an memorization", " Hadith", "Tawheed", "Fiqh", "Arabic language", "Imla' (Dictation)", "An-Nasheed (Poems)", "Adhkar", "Khattu (writing)"]},
  {id : 5, url : 'p-4', classname : 'Primary 4', color: colorYellow, aboutClass: "", subjects: ["Qur'an Reading", "Qur'an memorization", "Hadith", "Tawheed", "Fiqh", "Arabic language", "Nahwu (Syntax)", "An-Nasheed (Poems)", "Adhkar", "Khattu (writing)", "Imla' (Dictation)", "Tajweed"]},
  {id : 6, url : 'p-5', classname : 'Primary 5', color: colorYellow, aboutClass: "", subjects: ["Qur'an Reading", "Qur'an memorization", "Hadith", "Tawheed", "Fiqh", "Arabic language", "Nahwu (Syntax)", "An-Nasheed (Poems)", "Adhkar", "Khattu (writing)", "Imla' (Dictation)", "Tajweed"]},
  {id : 7, url : 'js-1', classname : 'J S 1', color: colorRed, aboutClass: "", subjects: ["Qur'an Reading", "Qur'an memorization", "Hadith", "Tawheed", "Fiqh", "Arabic language", "Nahwu (Syntax)", "An-Nasheed (Poems)", "Adhkar", "Khattu (writing)", "Imla' (Dictation)", "Tajweed", "Mustalahul Hadith", "Sarf (Morphology)", "Ta'rikh (History of the prophet)"]},
  {id : 8, url : 'js-2', classname : 'J S 2', color: colorRed, aboutClass: "", subjects: ["Adhkaar", "Qur'an", "Nahwu", "Sorfu", "Arabiyyah", "Hadith", "Seeroh", "Fiqh", "Mustolahul Hadith", "Tawheed", "Tajweed", "Imlahu", "Nasheed", "Tafseerul-Qur'an", "Insha'"]},
];


// LECTURES
const fetchLectures = async () => {
  try {
    const querySnapshot = await getDocs(collection(db2, 'lectures'));
    const lectureList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    return lectureList;
  } catch (error) {
    console.error('Error fetching lectures:', error);
  }
};


const lecturesData = await fetchLectures();
console.log(`lecture ${lecturesData}`);

// BLOG
const fetchNews = async () => {
  try {
    const querySnapshot = await getDocs(collection(db2, 'news'));
    const newsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return newsData;
  } catch (error) {
    alert('Error fetching news: ', error);
  }
};

const newsData = await fetchNews();


const charitiesData = [
  {
    id: 1,
    name: "RAMADAN",
    desc: "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
    imgUrl: "/images/ramadan.jpg"
  },
  {
    id: 2,
    name: "ORPHANAGE",
    desc: "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
    imgUrl: "/images/orphanage.jpg"
  },
  {
    id: 3,
    name: "PRISON",
    desc: "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
    imgUrl: "/images/prison.jpg"
  },
];


export { classesData, lecturesData, charitiesData, newsData };
