import React from 'react'
import { NavLink } from 'react-router-dom';
import LanguageSelector from '../GENERAL/LanguageSelector';
import { motion } from 'framer-motion'
// import LanguageSelector from './LanguageSelector';

function Navbar() {
  return (
  <>
  <nav className="bg-white border-gray-200 md:mx-20">
    <div className="w-full flex flex-wrap items-center justify-between mx-auto ">
      <a href="/" className="flex text-xs md:text-base  items-center space-x-3 rtl:space-x-reverse p-4"> 
        <div className="logo flex items-center pe-10">
          <motion.img initial={{ opacity: 0, rotateY: -180 }} animate={{ opacity: 1, rotateY: 0 }} exit={{ opacity: 0, rotateY: 180 }} transition={{ type:"tween", duration: 0.8, delay: 0.5 }} className=" w-14 md:w-24" src="/images/logo1.png" alt="" />
          <div className="logo-text ms-3">
            <h2 className=" font-bold">DAARURAHMAH</h2>
            <h5><span className="font-semibold">Motto:</span> The Mercy of Allah is Near!</h5>
          </div>
        </div>
      </a>
      <button
      data-collapse-toggle="navbar-multi-level"
      type="button"
      className="inline-flex items-center m-4 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      aria-controls="navbar-multi-level"
      aria-expanded="false"
      >
      <span className="sr-only">Open main menu</span>
      <svg
      className="w-5 h-5"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 17 14"
      >
      <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1 1h15M1 7h15M1 13h15"
      />
    </svg>
  </button>
  <div className="hidden p-4 md:block md:w-auto md:me-5 w-full md:bg-white bg-gray-500" id="navbar-multi-level">
    <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
      <li>
        
        <NavLink activeClassName="active" exact to="/" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent" >Home</NavLink>
      </li>
      <li>
        <NavLink activeClassName="active" to="/about" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent" >About</NavLink>
      </li>
      <li>
        <button id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar" className="flex items-center justify-between w-full py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent">School 
        <svg className="w-2.5 h-2.5 ms-2.5 block md:hidden" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 10">
  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1v8M1 5h8" />
</svg>

<svg
          className="w-2.5 h-2.5 ms-2.5 hidden md:block "
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
          >
          <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="m1 1 4 4 4-4"
          />
        </svg>

</button>
        { /* Dropdown menu */ }
        <div id="dropdownNavbar" className="z-50 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:divide-gray-600">
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelled="doubleDropdownButton1">
            <li>
              <NavLink activeClassName="active" to="/school/lectures" className="block px-4 py-2 hover:bg-gray-100">SCHOOL LECTURES</NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/school/calender" className="block px-4 py-2 hover:bg-gray-100">CALENDAR</NavLink>
            </li>
          </ul>
          
        </div>
      </li>
      <li>
        <button id="dropdownNavbarLink1" data-dropdown-toggle="dropdownNavbar1" className="flex items-center justify-between w-full py-2 px-3 text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:hover:bg-gray-700 md:dark:hover:bg-transparent">
          Classes{" "}
          <svg className="w-2.5 h-2.5 ms-2.5 block md:hidden" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 10">
  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1v8M1 5h8" />
</svg>
<svg
          className="w-2.5 h-2.5 ms-2.5 hidden md:block "
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
          >
          <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="m1 1 4 4 4-4"
          />
        </svg>
        </button>
        {/* Dropdown menu */}
        <div id="dropdownNavbar1" className="z-50 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:divide-gray-600" >
        <ul
        className="py-2 text-sm text-gray-700 dark:text-gray-200"
        aria-labelledby="dropdownLargeButton1"
        >
        <li>
          <NavLink activeClassName="active" to="/classes/n-1" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 ">NURSERY 1</NavLink>
        </li>
        {/* PRIMARY SECTION */}
        <li aria-labelledby="dropdownNavbarLink2">
          <button
          id="doubleDropdownButton2"
          data-dropdown-toggle="doubleDropdown2"
          data-dropdown-placement="right-start"
          type="button"
          className="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
          >
          PRIMARY

          <svg className="w-2.5 h-2.5 ms-2.5 block md:hidden" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 10">
  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1v8M1 5h8" />
</svg>
<svg
          className="w-2.5 h-2.5 ms-2.5 hidden md:block "
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
          >
          <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      <div
      id="doubleDropdown2"
      className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
      >
      <ul
      className="py-2 text-sm text-gray-700 dark:text-gray-200"
      aria-labelledby="doubleDropdownButton2"
      >
      <li>
        <NavLink activeClassName="active" to="/classes/p-1" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">1</NavLink>
      </li>
      <li>
        <NavLink activeClassName="active" to="/classes/p-2" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" >2</NavLink>
      </li>
      <li>
        <NavLink activeClassName="active" to="/classes/p-3" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">3</NavLink>
      </li>
      <li>
        <NavLink activeClassName="active" to="/classes/p-4"  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" >4</NavLink>
      </li>
      <li>
        <NavLink activeClassName="active" to="/classes/p-5" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">5</NavLink>
      </li>
    </ul>
  </div>
</li>
<li aria-labelledby="dropdownNavbarLink3">
  <button  id="doubleDropdownButton3"  data-dropdown-toggle="doubleDropdown3"  data-dropdown-placement="right-start"  type="button"  className="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" >
    J S
    <svg className="w-2.5 h-2.5 ms-2.5 block md:hidden" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 10">
  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1v8M1 5h8" />
</svg>
<svg
          className="w-2.5 h-2.5 ms-2.5 hidden md:block "
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
          >
          <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="m1 1 4 4 4-4"
          />
        </svg>
  </button>
  <div id="doubleDropdown3" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="doubleDropdownButton3">
      <li>
        <NavLink activeClassName="active" to="/classes/js-1" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">1</NavLink>
      </li>
      <li>
        <NavLink activeClassName="active" to="/classes/js-2" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">2</NavLink>
      </li>
      
      
    </ul>
  </div>
</li>
</ul>

</div>
</li>
<li>
  <NavLink activeClassName="active" to="/blog" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">News</NavLink>
</li>

<li>
  <NavLink activeClassName="active" to="/contact" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</NavLink>
</li>

</ul>
</div>
</div>
<div className="flex justify-center w-full">
  {/* <LanguageSelector/> */}
  <LanguageSelector/>
</div>

</nav>

</>
)
}

export default Navbar;
