
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyACcJQWkyo3LwD0i9B5EqLjDUxoZ09lM7M",
    authDomain: "daarurahmah-72c88.firebaseapp.com",
    projectId: "daarurahmah-72c88",
    storageBucket: "daarurahmah-72c88.appspot.com",
    messagingSenderId: "1098181273802",
    appId: "1:1098181273802:web:20ef0e46270d70521e2c33"
};

const app = initializeApp(firebaseConfig, "First App");

const db = getFirestore(app);

export { db };
