import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";

const secondApp = {
  apiKey: "AIzaSyCI1921S_Kv04SuzyPKPk4eyoSSKF5Grfc",
  authDomain: "daaruh-rahmah.firebaseapp.com",
  projectId: "daaruh-rahmah",
  storageBucket: "daaruh-rahmah.appspot.com",
  messagingSenderId: "671120635297",
  appId: "1:671120635297:web:b99afa3d229541637907e0"
};

const app2 = initializeApp(secondApp, "Generate-Code-App");
const db2 = getFirestore(app2);
const auth = getAuth(app2);

export { db2, auth };