import React from 'react'

function SchCalender() {

  const ftCalender = [
    {sn: "1", activity: "First Term Begins", days: "Monday", hijirah: "6 Shawwal 1445", gregorian: "15 April, 2024"},
    {sn: "2", activity: "P. T. A Meeting", days: "Sunday", hijirah: "19 Shawwal 1445", gregorian: " 28 April, 2024"},
    {sn: "3", activity: "Monthly Lecture", days: "Sunday", hijirah: "18 Dhul Qo’dah 1445", gregorian: "26 May, 2024"},
    {sn: "4", activity: "Eidul-Kabir  Holiday Begins", days: "Thursday", hijirah: "7 Dhul  hijjah1445", gregorian: "13 June, 2024"},
    {sn: "5", activity: "Eidul-Kabir  Holiday Ends", days: "Friday", hijirah: "15 Dhul-hijjah 1445", gregorian: "21 June, 2024"},
    {sn: "6", activity: "Teachers’ meeting", days: "Thursday", hijirah: "21 Dhul-Hijjah 1445", gregorian: "27 June, 2024"},
    {sn: "7", activity: "Test in all subjects (wk12)			", days: "Sat & Sun ", hijirah: "30-1Muharram 1446", gregorian: "6th & 7th July, 2024"},
    {sn: "8", activity: "Exam Begins", days: "Saturday", hijirah: "7 Muharram 1446", gregorian: "13 July, 2024"},
    {sn: "9", activity: "Exam Ends", days: "Thursday", hijirah: "19 Muharram 1446", gregorian: "25 July,2024"},
    {sn: "10", activity: "Holiday Begins", days: "Saturday", hijirah: "21 Muharram 1446", gregorian: "27 July. 2024"},
    {sn: "11", activity: "Holiday Ends", days: "Friday", hijirah: "27 Muharram 1446", gregorian: "2 Aug. 2024"},
   
  ];
  const stCalender = [

    {sn: "1", activity: "Second Term Begins", days: "Saturday", hijirah: "28 Muharram 1446", gregorian: "3 Aug. 2024"},
    {sn: "2", activity: "1st Monthly Lecture", days: "Sunday", hijirah: "19 Sofar 1446", gregorian: "25 Aug. 2024"},
    {sn: "3", activity: "2nd Monthly Lecture", days: "Sunday", hijirah: "25 R. Awwal 1446", gregorian: "29 Sept. 2024"},
    {sn: "4", activity: "Teachers’ meeting", days: "Thursday", hijirah: "6 R. Thani 1446 ", gregorian: "10 Oct. 2024"},
    {sn: "5", activity: "Test (12 wk)", days: "	Sat &Sun.", hijirah: "22&23 R.Thani 1446", gregorian: "26&27 Oct.2024"},
    {sn: "6", activity: "Exam Begins", days: "Saturday", hijirah: "29 R. Thani 1446", gregorian: "2 Nov. 2024"},
    {sn: "7", activity: "Exam Ends", days: "Sunday", hijirah: "15 J. Awwal 1446", gregorian: "17 Nov. 2024"},
    {sn: "8", activity: "Holiday Begins", days: "Monday", hijirah: "16 J. Awwal 1446", gregorian: "18 Nov. 2024"},
    {sn: "9", activity: "Holiday Ends", days: "Sunday", hijirah: "22 J. Awwal 1446", gregorian: "24 Nov. 2024"},
  ];
  const thrdCalender = [
    {sn: "1", activity: "Third Term Begins", days: "Monday", hijirah: "23 J. Awwal 1446", gregorian: "25 Nov. 2024"},
    {sn: "2", activity: "1st Monthly Lecture", days: "Sunday", hijirah: "27 J. Thani 1446", gregorian: "29th Dec. 2024"},
    {sn: "3", activity: "Teachers’ Meeting", days: "Thursday", hijirah: "2 Rajab 1446", gregorian: "2. Jan. 2025"},
    {sn: "4", activity: "P.T.A Meeting", days: "Sunday", hijirah: "12  Rajab 1446", gregorian: "12 Jan.2025"},
    {sn: "5", activity: "2nd Monthly Lecture", days: "Sunday", hijirah: "26 Rajab 1446", gregorian: "26 Jan. 2025"},
    {sn: "6", activity: "Test (12 Wk)", days: "Sat -Thur", hijirah: "2-7 Sha’ban 1446", gregorian: "1-6 Feb. 2025"},
    {sn: "7", activity: "Exam Begins", days: "Saturday", hijirah: "9 Sha’ban 1446", gregorian: "8 Feb. 2025"},
    {sn: "8", activity: "Exam Ends", days: "Thursday", hijirah: "21 Sha’ban 1446", gregorian: "20 Feb. 2025"},
    {sn: "9", activity: "Preparation of Report Sheet", days: "Sat-Thur", hijirah: "23-28  Sha’ban 1446", gregorian: "22-27  Feb. 2025"},
    {sn: "10", activity: "Collection of Report Sheet & Holiday Begins", days: "Saturday", hijirah: "1 Ramadan 1446", gregorian: "1 March 2025"},
    {sn: "11", activity: "Holiday Ends", days: "Sunday", hijirah: "7 Shawwal 1446", gregorian: "6 April, 2025"},
    {sn: "12", activity: "Resumption Date", days: "Monday", hijirah: "8 Shawwal 1445", gregorian: "7 April, 2025"},
  ];
   
  return (
  <>
  <div className="mx-5 md:mx-10">
    <div className="text-center mb-8">
    <h1 className="text-[20px] md:text-[30px] font-bold">ACADEMIC CALENDAR</h1>
    <h3>1445/1446 A.H. (2024/2025) SESSION</h3>
    </div>
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
          <tr>
             <th scope="col" className="px-6 py-3 ">
             S/N
            </th>
            <th scope="col" className="px-6 py-3 bg-gray-50 ">
              ACTIVITIES
            </th>
            <th scope="col" className="px-6 py-3">
              DAYS
            </th>
            <th scope="col" className="px-6 py-3 bg-gray-50 ">
              HIJRAH
            </th>
            <th scope="col" className="px-6 py-3">
              GREGORIAN
            </th>
          </tr>
        </thead>
        <tbody>
          <h1 className="font-bold text-center w-full text-[12px] md:text-[20px]">  FIRST TERM</h1>
            {ftCalender.map((calender) => (
               <tr className="border-b border-gray-200 dark:border-gray-700">
               <th scope="row" className="px-6 py-3 ">
                   {calender.sn}
                 </th>
                 <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50  ">
                  {calender.activity}
                 </th>
                 <td className="px-6 py-4">
                 {calender.days}
                 </td>
                 <td className="px-6 py-4 bg-gray-50 ">
                 {calender.hijirah}
                 </td>
                 <td className="px-6 py-4">
                   {calender.gregorian}
                 </td>
               </tr>
            ))}
            <h1 className="font-bold text-center w-full text-[12px] md:text-[20px]">  SECOND TERM</h1> 
            {stCalender.map((calender) => (
               <tr className="border-b border-gray-200 dark:border-gray-700">
               <th scope="row" className="px-6 py-3 ">
                   {calender.sn}
                 </th>
                 <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50  ">
                  {calender.activity}
                 </th>
                 <td className="px-6 py-4">
                 {calender.days}
                 </td>
                 <td className="px-6 py-4 bg-gray-50 ">
                 {calender.hijirah}
                 </td>
                 <td className="px-6 py-4">
                   {calender.gregorian}
                 </td>
               </tr>
            ))}
             <h1 className="font-bold text-center w-full text-[12px] md:text-[20px]">  THIRD TERM</h1> 
            {thrdCalender.map((calender) => (
               <tr className="border-b border-gray-200 dark:border-gray-700">
               <th scope="row" className="px-6 py-3 ">
                   {calender.sn}
                 </th>
                 <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50  ">
                  {calender.activity}
                 </th>
                 <td className="px-6 py-4">
                 {calender.days}
                 </td>
                 <td className="px-6 py-4 bg-gray-50 ">
                 {calender.hijirah}
                 </td>
                 <td className="px-6 py-4">
                   {calender.gregorian}
                 </td>
               </tr>
            ))}
        </tbody>
      </table>
    </div>
      <p className="text-center my-10 text-blue-500">For more Enquiries: <a className="hover:underline" href="tel:08081208720">08081208720</a>, <a className="hover:underline" href="tel:08107598252">08107598252</a>, <a className="hover:underline" href="tel: +2349135354420"> +2349135354420</a></p>
  </div>
  </>
  )
}

export default SchCalender
