import React from 'react'

function TheClass(props) {
  return (
    <>
        <div className="the-class m-10 md:m-20">
            <h1 className={`class-title text-center font-bold md:text-4xl ${props.color} uppercase`}>{props.classname}</h1>
            <p className="class-desc my-10">{props.aboutClass}</p>
            <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">SUBJECTS</h2>
            <div className="class-subject">
                <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-yellow-700">
                    {props.subjects.map((subject) => (

                        <li>
                            {subject}
                        </li>
                    ))}
                   
                </ul>
            </div>
        </div>
    </>
  )
}

export default TheClass

