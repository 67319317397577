import React, { useEffect } from 'react'
import { initFlowbite } from 'flowbite'
import { Link } from 'react-router-dom'
import CharCard from '../utils/CharCard'
import { charitiesData } from '../Data/Data'
import CopyButton from '../utils/CopyBtn';

function Charity() {
  useEffect(() => { 
    initFlowbite();
},[]);
  const charities = charitiesData;
  return (
    <>
    <div id="popup-modal" tabIndex={-1} className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span className="sr-only">Close modal</span>
              </button>
              <div className="p-4 md:p-5 text-center">
                 <div className="text">
                  <h3 className="mb-5 text-lg font-bold text-gray-500 ">ACCOUNT NAME : <span className="text-black">DAARURAHMAH</span></h3>
                  <h3 className="mb-5 text-lg font-bold text-gray-500 ">BANK : <span className="text-black">JAIZ BANK</span></h3>
                  <h3 className="mb-5 text-lg font-bold text-gray-500 ">ACCOUNT NUMBER : <span className="text-black">0005449202</span></h3>
                 </div>
                  <CopyButton textToCopy="0927351723" />
              </div>
            </div>
        </div>
      </div>
        <section className="welcome">
        <nav>
            <Link to="/" className="logo pt-3">
                <img src="/images/logo1.png" className="w-[90px] md:w-[150px]" alt="" srcset=""/>
            </Link>
        </nav>
        <div className="welcome-txt px-5">
            <h2 className="">"In our charity section, we extend our hands beyond borders, weaving a tapestry of hope and compassion, enriching lives one act of kindness at a time."</h2>            
        </div>
    </section>
    <section className="charity px-9 md:px-20 py-32">
    
      {charities.map((charityInfo) => (
        <CharCard key={charityInfo.id}  charityIn={charityInfo} />
      )
      )}
    </section>
    </>
  )
}

export default Charity
