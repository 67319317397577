import { motion } from 'framer-motion';
function MyCarousel() {
  const mystyle = {
    height : '450px',
  }
  
  return (
  <>
  
  
  <div
  id="indicators-carousel"
  className="relative w-full"
  data-carousel="static"
  style={mystyle}
  >
  <div className="relative h-96 overflow-hidden rounded-lg md:h-full">
    <div
    className="hidden duration-700 ease-in-out"
    data-carousel-item="active"
    >
    <img
    src="../images/student-16.jpg"
    className="w-full h-full object-cover"
    alt="..."
    />
    { /* Dark overlay */ }
    <div className="absolute inset-0 bg-black opacity-50" />
    { /* Text content */ }
    
    <motion.div  animate={{ scale: 1 }} initial={{ scale: 0 }} transition={{ type: "tween", delay: 1 , duration: 1 }} className="absolute inset-0 flex flex-col items-center justify-center text-white">
      <h1 className="lg:text-4xl text-sm text-center font-bold mb-2">Daarurahmah: House of Knowledge and Piety</h1>
      <p className="text-center lg:text-lg lg:mx-20 text-base mx-5 mb-8">Welcome to Daarurahmah, where knowledge meets piety. Join us for an enriching journey of learning and spiritual growth.</p>
      <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
        
        <a href="/form" className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400">
          Register Your Child with Us
        </a>
      </div>
    </motion.div>
  </div>
  <div className="hidden duration-700 ease-in-out" data-carousel-item="">
    <img
    src="../images/student-8.jpg"
    className="w-full h-full object-cover"
    alt="..."
    />
    { /* Dark overlay */ }
    <div className="absolute inset-0 bg-black opacity-50" />
    { /* Text content */ }
    <motion.div animate={{ scale: 1 }} initial={{ scale: 0 }} transition={{ type: "tween", delay: 1 , duration: 1 }} className="absolute inset-0 flex flex-col items-center justify-center text-white">
      <h1 className="lg:text-4xl text-sm text-center font-bold mb-2">Discover Daarurahmah: Enlightening Paths, Embracing Faith</h1>
      <p className="text-center lg:text-lg lg:mx-20 text-base mx-5 mb-8">Step into Daarurahmah, where we illuminate paths of knowledge while embracing the essence of faith. Join us in fostering a harmonious blend of learning and spiritual enrichment.</p>
      <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
        <a href="/form" className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400">
          Register Your Child with Us
        </a>
      </div>
    </motion.div>
  </div>
</div>
<div className="absolute z-30 flex -translate-x-1/2 space-x-3 rtl:space-x-reverse bottom-5 left-1/2">
  <button
  type="button"
  className="w-3 h-3 rounded-full"
  aria-current="true"
  aria-label="Slide 1"
  data-carousel-slide-to={0}
  />
  <button
  type="button"
  className="w-3 h-3 rounded-full"
  aria-current="false"
  aria-label="Slide 2"
  data-carousel-slide-to={1}
  />
</div>
<button
type="button"
className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
data-carousel-prev=""
>
<span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
  <svg
  className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
  aria-hidden="true"
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 6 10"
  >
  <path
  stroke="currentColor"
  strokeLinecap="round"
  strokeLinejoin="round"
  strokeWidth={2}
  d="M5 1 1 5l4 4"
  />
</svg>
<span className="sr-only">Previous</span>
</span>
</button>
<button
type="button"
className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
data-carousel-next=""
>
<span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
  <svg
  className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
  aria-hidden="true"
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 6 10"
  >
  <path
  stroke="currentColor"
  strokeLinecap="round"
  strokeLinejoin="round"
  strokeWidth={2}
  d="m1 9 4-4-4-4"
  />
</svg>
<span className="sr-only">Next</span>
</span>
</button>
</div>


</>

);
}

export default MyCarousel;