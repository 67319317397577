import React from 'react'
import { Link } from 'react-router-dom'

function Classes() {
  const classes = [
    {id : 1, url : 'n-1', classname : 'Nursery 1' },
    {id : 2, url : 'p-1', classname : 'Primary 1' },
    {id : 3, url : 'p-2', classname : 'Primary 2' },
    {id : 4, url : 'p-3', classname : 'Primary 3' },
    {id : 5, url : 'p-4', classname : 'Primary 4' },
    {id : 6, url : 'p-5', classname : 'Primary 5' },
    {id : 7, url : 'js-1', classname : 'J S 1' },
    {id : 8, url : 'js-2', classname : 'J S 2' },
  ];
  return (
    <>
    <div className="class-sec">
      <h1 className="text-center text-4xl font-bold">Our Classes</h1>
      <div className="classes grid grid-cols-2 md:grid-cols-3 gap-4 m-10">
        {classes.map((ourclass) => (
             <div key={ourclass.id} className="class">
             <div className="max-w-sm p-6 flex flex-col justify-center items-center bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
               {/* <a >
                 
               </a> */}
               <Link to={`/classes/${ourclass.url}`}>
                 <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {ourclass.classname}
                 </h5>
               </Link>
               <a
               
               
               >
              
           </a>
           <Link to={`/classes/${ourclass.url}`} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" >
           Read more
               <svg
               className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
               aria-hidden="true"
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 14 10"
               >
               <path
               stroke="currentColor"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeWidth={2}
               d="M1 5h12m0 0L9 1m4 4L9 9"
               />
             </svg>
           </Link>
         </div>
         
       </div>
        ))}
        
      </div>
    </div>
    </>
    )
  }
  
  export default Classes
  